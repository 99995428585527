import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ICardDocument, ICardListInfoData } from '../../components/cards/card-item.interface';
import { debounceTime, of, Subscription, switchMap, tap } from 'rxjs';
import { Role } from 'src/app/models/user/role.model';
import { MBRDocumentsService } from 'src/app/services/mbr-documents.service';
import { UsersService } from 'src/app/services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IHeaderAction } from '../../components/header-actions/header-actions.interface';
import { MatDrawer } from '@angular/material/sidenav';
import { UploadDocumentsComponent } from '../../components/upload-documents/upload-documents.component';
import { PopupConfirmationComponent } from '../../components/popup-confirmation/popup-confirmation.component';
import { DeleteConfirmationDialogService } from 'src/app/services/delete-confirmation-dialog.service';

@Component({
    selector: 'app-product-mbr-detail',
    templateUrl: './product-mbr-detail.component.html',
    styleUrls: ['./product-mbr-detail.component.scss'],
    standalone: false
})
export class ProductMbrDetailComponent implements OnInit, OnDestroy {
  productId: any;
  lenderId: any;
  isLoading: boolean;
  userRole: Role | undefined;
  public canUpload: boolean = false;
  public product: { name: string } | undefined;
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild(UploadDocumentsComponent)
  documentUploader: UploadDocumentsComponent;

  public dataProductMbrs: any[] = [];

  formActions: IHeaderAction[] = [
    {
      id: 'markDefault',
      title: 'Mark Default',
      type: 'stroke',
      icon: 'edit',
      class: 'primary',
    }
  ];

  pageSettings: {
    defaultPageSize: number,
    pageSizeOptions: number[],
  } = {
    defaultPageSize: 5,
    pageSizeOptions: [5,10,15]
  }
  datePipe = new DatePipe('en-US');
  currentPage: number = 0;
  pageSize: number = this.pageSettings.defaultPageSize;
  searchControl: FormControl = new FormControl('');
  dataToShow: any[];

  subscriptions: Subscription[] = [];
  loadingValidator: boolean;

  constructor(
    private mbrService: MBRDocumentsService,
    private userService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private deleteDialog: DeleteConfirmationDialogService,
  ) {

  }

  ngOnInit() {
    this.productId = this.route.snapshot.paramMap.get('productId');
    this.lenderId = this.route.snapshot.paramMap.get('lenderId');

    const userSubscription = this.userService.userRole$.subscribe((role) => {
      this.userRole = role;
    });
    this.subscriptions.push(userSubscription);

    const searchSubscription =this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.filterAndPaginateData();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    this.reload();
  }

  private filterAndPaginateData() {
    const searchValue = this.searchControl.value?.toLowerCase() || '';
    const filteredData = this.dataProductMbrs.filter(item => {
      const document = item.name?.toLowerCase() || '';
      const version = item.version?.toString().toLowerCase() || '-';

      return (
        document.includes(searchValue) ||
        version.includes(searchValue)
      );
    });

    const startIndex = this.currentPage * this.pageSize;
    const paginatedData = filteredData.slice(startIndex, startIndex + this.pageSize);

    this.dataToShow = this.transformCardData(paginatedData);
  }

  onRequestMBR(){
    if(this.isAdmin()){
      this.router.navigate(['/admin/mbr-request/create']);
    }
    else{
      this.router.navigate(['/lender/mbr-request/create']);
    }
  }

  onMakeDefault(data: any) {
    this.deleteDialog
      .openDeleteConfirmation(
        `This document will become the default MBR.`,
        'Confirmation',
        'Confirm'
      )
      .afterClosed()
      .pipe(
        switchMap((dialogClosed) => {
          if (dialogClosed){
            if(this.isAdmin()){
              return this.mbrService
              .makeDefaultByLender(this.productId, this.lenderId, data.version)
            }
            else{
              return this.mbrService
                .makeDefault(this.productId, data.version)
            }
          }
          else return of(false);
        })
      )
      .subscribe((deleteConfirmation) => {
          this.reload();
      });
  }

  close() {
    this.drawer.close();
  }

  isAdmin() {
    return this.userRole == Role.Admin;
  }

  private reload() {
    this.isLoading = true;
    let subscription: Subscription;
    if(this.isAdmin()){
      subscription = this.mbrService.getByProductAndLender(this.productId, this.lenderId).pipe(
        switchMap((response: any) => {
          if (!response.isError) {
            return of(response.data);
          } else {
            return of([]);
          }
        })).subscribe(data => {
          this.dataProductMbrs = data.documents;
          this.product = data.product;
          this.filterAndPaginateData();
          this.isLoading = false;
          this.drawer.close();
        }
      );
    }
    else{
      subscription = this.mbrService.getByProduct(this.productId).pipe(
        switchMap((response: any) => {
          if (!response.isError) {
            return of(response.data);
          } else {
            return of([]);
          }
        })).subscribe(data => {
          this.dataProductMbrs = data.documents;
          this.product = data.product;
          this.filterAndPaginateData();
          this.isLoading = false;
          this.drawer.close();
        }
      );
    }
    this.subscriptions.push(subscription);
  }

  private transformCardData(data: any) {
    const infoFormSubmission: ICardListInfoData[] = data.map(
      (action: any) => {
        let infoData = this.buildInfoData(action);
        return infoData;
      }
    );

    return infoFormSubmission;
  }

  private buildInfoData(
    action: any,
  ): ICardListInfoData {
    let list = [
      {
        label: 'Version',
        value: action.version ?? '-'
      }
    ]
    const documents: ICardDocument[] = [];

    documents.push({
        label: action.name,
        value: action.serverFileName
      });

    const actions = action.isCurrent ? [] : this.formActions;
    const isCurrent = action.isCurrent;

    return {
      id: action.version,
      list,
      title: '',
      documents,
      actions,
      isCurrent
    } as ICardListInfoData;
  }

  handlePageEvent(event: PageEvent){
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.filterAndPaginateData();
  }

  onActionClicked(event: any) {
    const data = this.dataProductMbrs.find(d => d.version == event.id);
    if(!data) return;

    switch (event.actionId) {
      case 'markDefault':
        this.onMakeDefault(data);
        break;
      default:
        break;
    }
  }

  public fileRemoved(fileCount: number) {
    if (fileCount === 0) {
      this.canUpload = false;
    } else {
      this.canUpload = true;
    }
  }

  public uploadingEvent(isUploading: boolean) {
    this.isLoading = isUploading;
    if (this.documentUploader.files.length > 0) {
      this.canUpload = true;
    }
  }

  public onSubmit() {
    var data = {
      isActiveVersion: true,
      document: {
        serverFileName: this.documentUploader.files[0].serverFileName,
        name: this.documentUploader.files[0].file.name,
      },
    };

    if(this.isAdmin()){
      this.mbrService
        .uploadLenderMBR(this.productId, this.lenderId, data)
        .subscribe((r) => {
          this.reload();
          this.documentUploader.clearFiles();
        });
    }
    else{
      this.mbrService
        .uploadMBR(this.productId, data)
        .subscribe((r) => {
          this.reload();
          this.documentUploader.clearFiles();
        });
    }
  }

  onConfirmation(result: any) {
    if (result.confirmed) {
      this.mbrService
        .makeDefaultByLender(this.productId, this.lenderId, result.id)
        .subscribe(() => {
          this.reload();
        });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }
}
