import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICardListInfoData } from '../../cards/card-item.interface';
import { CardDashboardItemComponent } from '../../cards/card-dashboard-item/card-dashboard-item.component';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
    selector: 'app-dashboard-card',
    imports: [CommonModule, RouterModule, CardDashboardItemComponent, LoaderComponent],
    templateUrl: './dashboard-card.component.html',
    styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent {
  @Input() title?: string = '';
  @Input() total?: number | null = 0;
  @Input() listData?: ICardListInfoData[] | null = [];

  @Input() limitItems: number;
  @Input() headerRouteLink: string;
  @Input() detailRouteLink: string;

  @Input() modeFlex: 'half' | '3-2' | '4-1';
  @Input() modeBadge: 'small' | 'large';
  @Input() loading: boolean = false;


  getDetailRoute(id: number) {
    return `${this.detailRouteLink}/${id}`
  }
}
