<div class="submission-data-card">
    <table class="table table-bordered" *ngIf="submissionData">
        <tbody>
              <tr *ngIf="submissionData.administrator">
                <td><span class="data-label"><span class="data-label">Administrator</span></span></td>
                <td [attr.colspan]="6">
                  <span class="data-value">{{ submissionData.administrator.name }}</span>
                </td>

            </tr>
            <tr>
                <td><span class="data-label"><span class="data-label">System No:</span></span></td>
                <td [attr.colspan]="submissionData.previousApprovedFormNo ? undefined : 2"><span class="data-value">{{ submissionData.formNo }}</span></td>
                <td *ngIf="submissionData.previousApprovedFormNo"><span class="data-label">Previously Approved Form System No:</span></td>
                <td *ngIf="submissionData.previousApprovedFormNo"><span class="data-value">{{ submissionData.previousApprovedFormNo }}</span></td>
                <td><span class="data-label">Form No:</span></td>
                <td [attr.colspan]="submissionData.previousApprovedFormNo ? undefined : 2"><span class="data-value">{{ submissionData.providerFormNo }}</span></td>
            </tr>
            <tr>
                <td><span class="data-label">Submission Type:</span></td>
                <td><span class="data-value">{{ submissionData.submissionType.name }}</span></td>
                <td><span class="data-label">Product:</span></td>
                <td><span class="data-value">{{ submissionData.product.name }}</span></td>
                <td><span class="data-label">Industry:</span></td>
                <td><span class="data-value">{{ submissionData.industry.name }}</span></td>
            </tr>
            <tr>
                <td><span class="data-label">Review Deadline:</span></td>
                <td colspan="2"><span class="data-value">{{ submissionData.dueDate | date: 'MM-dd-yyyy' }}</span></td>
                <td><span class="data-label">Intended Date of Use</span></td>
                <td colspan="2"><span class="data-value">{{ submissionData.intendedDateOfUse | date: 'MM-dd-yyyy' }}</span></td>
            </tr>
            <tr>
                <td><span class="data-label">States:</span></td>
                <td colspan="5">
                    <ul class="list-group list-group-horizontal-lg" *ngIf="submissionData.states">
                        <li class="list-group-item border-0" ><span class="data-label">{{ joinedStates() }}</span></li>
                    </ul>
                </td>
            </tr>
            <tr *ngIf="submissionData.provider && role !== 'Provider'">
                <td>
                    Provider:
                </td>
               <td colspan="5">
                    <ul class="list-group list-group-horizontal-lg">
                        <li class="list-group-item border-0" ><span class="data-label">{{submissionData.provider.name}}</span></li>
                    </ul>
               </td>
            </tr>
            <tr *ngIf="submissionData.formSubmissionStatus && role !== 'Lender'">
                <td>
                    Lender:
                </td>
               <td colspan="5">
                    <ul class="list-group list-group-horizontal-lg">
                        <li class="list-group-item border-0" *ngFor="let lender of submissionData.formSubmissionStatus">
                            <span class="data-label">{{ lender.name }}</span>
                        </li>
                    </ul>
               </td>
            </tr>
        </tbody>
    </table>
</div>
