import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from 'src/app/services/providers.service';
import { StatesService } from 'src/app/services/states.service';
import { ProductsService } from 'src/app/services/products.service';
import { IState } from 'src/app/models/state/state.model';
import { IProduct } from 'src/app/models/product/product.model';
import { IProviderOrganizationDocumentType } from 'src/app/models/provider/provider-license.model';
import { DocumentTypesService } from 'src/app/services/document-types.service';
import { debounceTime, of, Subscription, switchMap, tap } from 'rxjs';
import { Role } from 'src/app/models/user/role.model';
import { DeleteConfirmationDialogService } from 'src/app/services/delete-confirmation-dialog.service';
import { UsersService } from 'src/app/services/users.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { IHeaderAction } from 'src/app/modules/shared/components/header-actions/header-actions.interface';
import { DatePipe } from '@angular/common';
import { ICardListInfoData } from 'src/app/modules/shared/components/cards/card-item.interface';
import { PageEvent } from '@angular/material/paginator';
import { entityExistsValidator } from 'src/app/validators/entity-exists.validator';
import { ProviderOrganizationsService } from 'src/app/services/provider.organizations.service';
import { IData } from 'src/app/modules/shared/components/row-detail-data/row-details-data.component';
import { sortData } from '../../utils';
@Component({
    selector: 'app-providers-list',
    templateUrl: './providers-list.component.html',
    styleUrls: ['./providers-list.component.scss'],
    standalone: false
})
export class ProvidersListComponent implements OnInit {
  public organizationId: any;
  public states: IState[] = [];
  public products: IProduct[] = [];
  public documentTypes: IProviderOrganizationDocumentType[] = [];

  @ViewChild('drawer') drawer: MatDrawer;

  userRole: Role | undefined;
  isEditing = false;
  isLoading: boolean;
  id: number;

  form: FormGroup = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.email, Validators.required],
      updateOn: 'blur',
    }),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    organizationId: new FormControl('', [Validators.required]),
  });

  public dataProviderUsers: any[] = [];

  formActions: IHeaderAction[] = [
    {
      id: 'edit',
      title: 'Edit',
      type: 'stroke',
      icon: 'edit',
      class: 'primary',
    },
    {
      id: 'enable',
      title: 'Enable',
      type: 'stroke',
      icon: 'check',
      class: 'success no-text'
    },
    {
      id: 'disable',
      title: 'Disable',
      type: 'stroke',
      icon: 'close',
      class: 'danger no-text'
    }
  ];

  pageSettings: {
    defaultPageSize: number;
    pageSizeOptions: number[];
  } = {
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15],
  };
  datePipe = new DatePipe('en-US');
  currentPage: number = 0;
  pageSize: number = this.pageSettings.defaultPageSize;
  currentPageInvoices: number = 0;
  pageSizeInvoices: number = this.pageSettings.defaultPageSize;
  searchControl: FormControl = new FormControl('');
  dataToShow: any[];
  dataToShowInvoices: any[] = [];

  subscriptions: Subscription[] = [];
  organization: any;
  descriptionRowData: IData[] = [{
    title: 'Description',
    description: '-'
  }];

  currentSortField = {
    sortValue: ''
  };
  currentIsAscending = false;
  totalCount: number = 0;
  searchKey: string = '';

  sortForm: FormGroup = new FormGroup({
    sortField: new FormControl({
      sortValue: this.currentSortField.sortValue,
    }),
    isAscending: new FormControl(this.currentIsAscending),
  });

  sortOptions = [
    { label: 'Name', sortValue: 'firstName' },
    { label: 'Last Name', sortValue: 'lastName' },
    { label: 'Email', sortValue: 'email' },

  ];

  constructor(
    private providerService: ProvidersService,
    private organizationService: ProviderOrganizationsService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StatesService,
    private productsService: ProductsService,
    private deleteDialog: DeleteConfirmationDialogService,
    private userService: UsersService,
    private providerDocumentService: DocumentTypesService
  ) {

  }

  ngOnInit(): void {

    this.initOrganization();
    this.getStates();
    this.getProducts();
    this.getDocumentTypes();

    const searchSubscription = this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.currentPage = 0;
          this.reload();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

  }

  private initOrganization() {
    const userRoleSubscription = this.userService.userRole$.pipe(
          switchMap((role) => {
            this.userRole = role;
            if (role === Role.Provider) {
              return this.userService.organizationId$;
            } else {
              return [this.route.snapshot.paramMap.get('id')];
            }
          })
        )
        .subscribe((id) => {
          this.organizationId = id ? `${id}` : null;
          this.getOrganization();
          this.reload();

        });

    this.subscriptions.push(userRoleSubscription);
  }

  private transformCardData(data: any) {
    const infoFormSubmission: ICardListInfoData[] = data.map((action: any) => {
      let infoData = this.buildInfoData(action);
      return infoData;
    });

    return infoFormSubmission;
  }

  private buildInfoData(providerUser: any): ICardListInfoData {
    let list = [
      {
        label: 'Last Name',
        value: providerUser.lastName,
      },
      {
        label: 'Email',
        value: providerUser.email,
      },
      {
        label: 'Phone Number',
        value: providerUser.phoneNumber,
      },
    ];
    const title = providerUser.firstName;
    return {
      id: providerUser.id,
      list,
      title,
      actions: this.getActionsFor(providerUser)
    } as ICardListInfoData;
  }

  onActionClicked(event: any) {
    const data = this.dataProviderUsers.find((d) => d.id == event.id);

    if (!data) return;

    switch (event.actionId) {
      case 'detail':
        this.onView(data);
        break;
      case 'edit':
        this.openProviderSidebar(data);
        break;
      case 'enable':
      case 'disable':
        this.onChangeAvailability(data);
        break;
      default:
        break;
    }
  }

  onChangeAvailability(data: any) {
    this.deleteDialog
      .openDeleteConfirmation(
        `This provider user will be ${data.isActive ? 'disabled' : 'enabled'}.`,
        data.isActive ? 'Disable confirmation' : 'Enable confirmation',
        data.isActive ? 'Disable' : 'Enable'
      )
      .afterClosed()
      .pipe(
        switchMap((dialogClosed) => {
          if (dialogClosed)
            return this.providerService.changeUserAvailability(
              data.userId,
              !data.isActive
            );
          else return of(false);
        })
      )
      .subscribe((deleteConfirmation) => {
        if (deleteConfirmation) {
          this.reload();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((s) => s.unsubscribe());
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.reload();
  }

  private getProducts() {
    this.productsService.get().subscribe((data: any) => {
      if (!data.isError) {
        this.products = data.data.items;
      }
    });
  }

  private getStates() {
    this.stateService.get().subscribe(data => {
      if (!data.isError) {
        this.states = data.data;
      }
    });
  }

  private getDocumentTypes() {
    this.providerDocumentService.get().subscribe(data => {
      if (!data.isError) {
        this.documentTypes = data.data;
      }
    });
  }

  private getOrganization() {
    this.organizationService.getById(this.organizationId).subscribe((data) => {
      if (!data.isError) {
        this.organization = data.data;
        this.descriptionRowData = [
          {
            title: 'Description',
            description: this.organization.description
          },

        ]
      }
    });
  }

  private reload() {
    this.isLoading = true;
    this.searchKey = this.searchControl.value?.toLowerCase() || '';

    const subscription = this.providerService
      .getByOrganizationId(this.organizationId, {
        organizationId: this.organizationId,
        sortColumn: this.currentSortField.sortValue,
        sortOrder: this.currentIsAscending ? 'asc' : 'desc',
        page: this.currentPage + 1,
        pageSize: this.pageSize,
        searchKey: this.searchKey
      }).pipe(
        switchMap((response: any) => {
          if (!response.isError) {
            return of(response.data);
          } else {
            return of([]);
          }
        })
      )
      .subscribe((data) => {
        this.dataProviderUsers = data.items;
        this.totalCount = data.totalCount;
        this.dataToShow = this.transformCardData(this.dataProviderUsers)
        this.isLoading = false;
        this.form.reset();
        this.drawer.close();
      });
    this.subscriptions.push(subscription);
  }

  openProviderSidebar(data: any = null) {
    if (data) {
      this.id = data.id;
      this.form.controls['email'].setAsyncValidators([
        entityExistsValidator(this.providerService, data.email),
      ]);
      const getSus = this.providerService
        .getById(this.id.toString())
        .subscribe((res) => {
          if (!res.isError) {
            this.form.reset({
              email: res.data.email,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              phoneNumber: res.data.phoneNumber,
              organizationId: res.data.organizationId,
            });
          }
        });
      this.subscriptions.push(getSus);
      this.isEditing = true;
      this.form.controls['email'].disable();
    } else {
      this.form.controls['organizationId'].setValue(this.organizationId);
      this.form.controls['email'].setAsyncValidators([
        entityExistsValidator(this.providerService),
      ]);
      this.form.controls['email'].enable();
      this.isEditing = false;
    }
    this.form.updateValueAndValidity();
    this.drawer.toggle();
  }

  onView(data: any) {
    this.router.navigate([`/admin/providers-list/${data.id}`]);
  }

  close() {
    this.form.reset();
    this.drawer.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const data = this.form.value;

      if (this.isEditing) {
        const updateSuscription = this.providerService
          .update(this.id.toString(), data)
          .subscribe((data) => {
            this.reload();
          });
        this.subscriptions.push(updateSuscription);
      } else {
        const addSuscription = this.providerService
          .add(this.form.value)
          .subscribe((data) => {
            this.reload();
          });
        this.subscriptions.push(addSuscription);
      }
    }
  }

  isAdmin() {
    return this.userRole == Role.Admin;
  }

  public getActionsFor(employee: any): IHeaderAction[] {
    if(employee.isActive)
    {
      return this.formActions.filter(a => a.id !== 'enable');
    }
    else{
      return this.formActions.filter(a => a.id !== 'disable');
    }
  }

  setSortField() {
    const { sortField, isAscending } = this.sortForm.value;
    this.currentIsAscending = isAscending;
    this.currentSortField = sortField;

    this.reload();
  }

  resetSortField() {
    this.sortForm.setValue({
      sortField: this.currentSortField,
      isAscending: this.currentIsAscending
    })
  }
}
